// import Josh from 'joshjs';

$(window).on('load resize orientationchange', function () {
    // Global: Fade-out des Preloaders
    $('#loader').delay(100).fadeOut('slow');
    setTimeout(function () {
        // Global: Breite der Navigationspunkte mit Bold
        var menuLink = $('#menu a.nav-link');
        menuLink.css('width', 'auto');
        menuLink.addClass('fw-bold');
           menuLink.each(function () {
            $(this).css('width', $(this).outerWidth());
        });
        menuLink.removeClass('fw-bold');
    }, 100);
    // Global: Variablen-Definition für Navi
    var langSwitch = $('.lang-switch');
    var langList = $('.lang-list');
    var searchForm = $('.search-form');
    var navToggler = $('.navbar-toggler');
    var navCollapse = $('.navbar-collapse');
    // Global: Anzeige der Sub-Navi
    $('.dropdown-toggle').click(function () {
        langSwitch.removeClass('is-active');
        langList.addClass('hidden');
        searchForm.addClass('hidden');
        $(this).siblings().find('img').addClass('lazyload');
    });
    // Global: Animation des Burger-Icons
    navToggler.unbind('click').click(function () {
        langSwitch.removeClass('is-active');
        langList.addClass('hidden');
        searchForm.addClass('hidden');
        $(this).toggleClass('is-active');
        navCollapse.toggleClass('is-active');
    });
    // Global: Dropdown zu Sprachwahl
    langList.addClass('hidden');
    langSwitch.unbind('click').click(function () {
        searchForm.addClass('hidden');
        navToggler.removeClass('is-active');
        navCollapse.removeClass('is-active');
        $(this).toggleClass('is-active');
        langList.toggleClass('hidden');
        return false;
    });
    // Global: Suchleiste einblenden
    $('.search-trigger').unbind('click').click(function () {
        langSwitch.removeClass('is-active');
        langList.addClass('hidden');
        navToggler.removeClass('is-active');
        navCollapse.removeClass('is-active');
        searchForm.toggleClass('hidden');
        return false;
    });
    // Global: Fadein-Effekt beim Scrollen
    // const josh = new Josh({
    //     // DOM CSS class to Animate, default is "josh-js"
    //     initClass: "animate",
    //
    //     // Animation CSS class from Animate.css library
    //     animClass: "animate__animated",
    //
    //     // Element distance of viewport to triggering the animation. default is 0.2 means 20% of element view animation will trigger
    //     offset: 0.2,
    //
    //     // Animation will trigger on Mobile or not. Default is true
    //     animateInMobile: true,
    //
    //     // Animation will trigger on newly added element or not. Default is false
    //     onDOMChange: false,
    //
    // });


    // const joshJobs = new Josh({
    //     initClass: 'animateJobs',
    //     offset: '0'
    // });

    // Global: Header-Ausblenden bei Scrolling mit Sticky-Navigation
    // function headerOffset() {
    //     if (window.matchMedia('(min-width: 992px)').matches) {
    //         var scrollOffset = $(window).scrollTop();
    //         $('.brand').css('margin-top', -scrollOffset);
    //     } else {
    //         $('.brand').css('margin-top', 0);
    //     }
    // }

    // Global: Abstand der Inhalte analog der Header-Höhe
    // if (window.matchMedia('(min-width: 992px)').matches) {
    //     var headerHeight = $('#menu').outerHeight();
    //     $('main > *:first-child').css('padding-top', headerHeight + 0); // + 144
    // } else {
    //     var menuHeight = $('#menu nav').outerHeight();
    //     $('main > *:first-child').css('padding-top', menuHeight + 0); // + 32
    // }
    // Global: Abfeuern der Funktionen (je nach Screensize)
    // headerOffset();
    // if (window.matchMedia('(min-width: 992px)').matches) {
    //     $(window).on('scroll', function () {
    //         headerOffset();
    //     });
    // }
});

// function onViewport(el, elClass, offset, callback) {
//     /*** Based on http://ejohn.org/blog/learning-from-twitter/ ***/
//     var didScroll = false;
//     var this_top;
//     var height;
//     var top;
//
//     if(!offset) { var offset = 0; }
//
//     $(window).scroll(function() {
//         didScroll = true;
//     });
//
//     setInterval(function() {
//         if (didScroll) {
//             didScroll = false;
//             top = $(this).scrollTop();
//
//             $(el).each(function(i){
//                 this_top = $(this).offset().top - offset;
//                 height   = $(this).height();
//
//                 // Scrolled within current section
//                 if (top >= this_top && !$(this).hasClass(elClass)) {
//                     $(this).addClass(elClass);
//
//                     if (typeof callback == "function") callback(el);
//                 }
//             });
//         }
//     }, 100);
// }

